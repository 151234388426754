import dynamic from "next/dynamic";

import QuotoWrapper from "@components/layouts/quotoWrapper";
import MainBanner from "@components/quoto/mainBanner";

import { getPageProps } from "@services/initial-calls";
import { GetServerSideProps } from "next";
import React, { ReactElement } from "react";
import OffersList from "@components/shared/offersList";
import { useDomainContext } from "@hooks/useDomainContext";
const SimpleProcess = dynamic(() => import("@components/quoto/simpleProcess"));

export default function QuotoHome(): ReactElement {
    const { domain } = useDomainContext();
    return (
        <QuotoWrapper>
            <MainBanner />
            <div className="offers">
                <OffersList offers={domain?.defaultOffers} style={{}} />
            </div>
            <SimpleProcess />
        </QuotoWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
